#panelDeAluminio {
    position: relative;
    top: 0;
    left: 10%;
    width: 80%;
    height: 100%;
    font-family: Poppins;
}

#separador {
 position: relative;
    top: 0;
    left: 10%;
    width: 80%;
    height: 50px;
}

div {
    display: block;
}

h1 {
    grid-column-start: 2;
    grid-row-start: 2;
    text-align: center;
    grid-column-end: 13;
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-top: 4%;
    font-weight: bold;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
}

caption {
    display: table-caption;
    text-align: -webkit-center;
}

tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

td {
    display: table-cell;
    vertical-align: inherit;
}

a {
    text-decoration: none;
}

a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.producto-article-rigth {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    background: white;
    background: #0000000a;
}

.txt-r-article-p {
    grid-column-start: 2;
    grid-column-end: 6;
    font-family: Poppins;
    line-height: 20px;
    margin-top: 27%;
    width: 100%;
    text-align: left;
}

.img-r-article-p {
    grid-column-start: 1;
    grid-column-end: 14;
    overflow: hidden;
}

.btn-galery-productos {
    width: 70%;
    background: #bb1e36;
    position: relative;
    left: 15%;
    text-align: center;
    color: white;
    font-family: Poppins;
    border: none;
    border-radius: 6px;
    height: 38px;
    top: 0;
    cursor: pointer;
    margin-top: 4%;
}

.btn-galery-productos a {
    text-decoration: none;
    color: white;
    position: absolute;
    top: -22%;
    left: 38%;
}

.h2-1 {
    font-family: Poppins;
    font-size: 1.3rem;
}

.producto-article-left {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    background: #0000000a;
}

.txt-acri{
    grid-row-start: 4;
    grid-column-start: 4;
    grid-column-end: 11;
}
.txt-acri p{
    width: 100%;
    text-align: justify;
}
.txt-acri ul{
    width: 100%;
    text-align: justify;
}

.txt-l-article-p {
    grid-column-start: 8;
    grid-column-end: 12;
    font-family: Poppins;
    line-height: 24px;
    margin-top: 16%;
    width: 100%;
    text-align: left;
}

.txt-l-article-p tr {
    font-size: .9em;
}

.txt-l-article-p td {
    width: 50%;
}

.txt-l-article-p table {
    width: 100%;
    text-align: center;
    border-spacing: 10px;
}

.txt-l-article-p caption {
    font-weight: bold;
    margin-bottom: 4%;
}

.img-l-article-p {
    grid-column-start: 3;
    grid-column-end: 6;
    margin-top: 18%;
    margin-bottom: 18%;
    box-sizing: border-box;
    overflow: hidden;
}

.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0;
  margin: auto;
}

.flex-item {
    width: 25%;
}

.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
}
.Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  padding: 0.8em 1.2em;
  overflow: hidden;
  list-style: none;
  border: solid @bw white;
  background: fade(slategrey,20%);
  > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0; }
}

.Rtable--2cols > .Rtable-cell  { width: 100%; }



