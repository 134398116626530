#aboutUs {
	position: relative;
    top: 0;
    left: 10%;
    width: 80%;
    height: 100%;
    font-family: Poppins;
}

.header {
  position: relative;
  top: 0;
  left:5%; 
  height: 20%;
  width: 90%;
  background-image: url(/edificio.jpg);
  background-size: cover;
  text-align: center;
  vertical-align: middle;
}

.body {
  position: relative;
  top: 4%;
  left:5%; 
  height: 50%;
  width: 90%;
  overflow-y: auto;
  font-weight: normal;
}

.footer {
  position: relative;
  padding: 25px 0;
  top: 50px;
  left:5%;
  height: 30%;
  width: 90%;
}

.bold-text {
  font-weight: bold;
}

.font-red {
  color: red;
}

.text3d {
  position: relative;
  top: 40%;
  font: bold 147.5%/1 "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #fff;
  text-shadow: 0 1px 0 #ccc,
               0 2px 0 #c9c9c9,
               0 3px 0 #bbb,
               0 4px 0 #b9b9b9,
               0 5px 0 #aaa,
               0 6px 1px rgba(0,0,0,.1),
               0 0 5px rgba(0,0,0,.1),
               0 1px 3px rgba(0,0,0,.3),
               0 3px 5px rgba(0,0,0,.2),
               0 5px 10px rgba(0,0,0,.25),
               0 10px 10px rgba(0,0,0,.2),
               0 20px 20px rgba(0,0,0,.15);
}