#main {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px solid;
  /*
  background-image: url(/background.jpg);
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover; 
  color: white;
  */
}

#header {
  flex-grow: 0;
  height: 100px;
  width: 100%;
}

#principal {
  flex-grow: 1;
  height: calc(100% - 100px);
  overflow-y: auto;
  width: 100%;
}

